import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, catchError, Subject } from 'rxjs';
import { HttpService } from '../http/http.service';
import { SnackbarService } from '../snackBar/snackbar.service';
import { StorageService } from '../storage/storage.service';
import { Endpoints, ApiMethod, ErrorCodes, SuccessMessage, colorCodes, routePath, FailedMessage, USERGROUPS, HIDENOTIFY } from '../utils/constants';
import { CommonService } from '../common/common.service';
import { LoaderService } from '../loader/loader.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  [x: string]: any;

  user$ = new BehaviorSubject(null);
  private redirectUrl: any;
  protected navigate: boolean = false;
  permissionList: any[] = [];
  loginFailed: any = new Subject<any>();
  isOTPRequired: any = new Subject<any>();
  loginErrorValidation: any = new Subject<any>();

  constructor(
    private _router: Router,
    private _http: HttpService,
    private _storage: StorageService,
    private _snackBar: SnackbarService,
    private activatedRoute: ActivatedRoute,
    private _common: CommonService,
    private _loader: LoaderService
  ) {

    this._http.invalidUserCredentials.subscribe((data: any) => {
      this.loginFailed.next(data);
    });
  }

  setLayerDetails() {
    return new Promise((resolve, reject) => {
      this._http.requestCall(Endpoints.GET_GEO_MINIMAL_LAYER_LIST, ApiMethod.GET).subscribe((response: any) => {
        console.log('getLayerDetailsresponse: ', response)
        resolve({});
        this._storage.setDetails('layerDetails', JSON.stringify(response || {}));
      }, (error: any) => {
        reject({});
        console.log('getLayerDetails error: ', error)
      });
    })
  }

  login(loginPayload: any, enableLoginOtp: boolean): any {
    this.loginFailed.next(null);
    this.isOTPRequired.next(null);
    this._storage.deleteToken();

    this.redirectUrl = this.activatedRoute.snapshot.queryParams["redirectURL"];

    let endpoint: any = '';
    if (enableLoginOtp) {
      endpoint = `${Endpoints.POST_TOKEN_AUTH}?type=otp_verify`
    } else {
      endpoint = `${Endpoints.POST_TOKEN_AUTH}`;
    }
    const existingOptions = {
      headers: new HttpHeaders({
        'Error-Field-Affected': HIDENOTIFY,
      }),
    };
    this._loader.show();
    this._http.requestCall(endpoint, ApiMethod.POST, loginPayload, existingOptions)
    .pipe(catchError((err: any): any => {
      this._loader.hide();
      this.loginErrorValidation.next(err);
    }))
    .subscribe((loginResponse: any) => {
      this._loader.hide();
      // this.setLayerDetails().then(() => {});

      if (loginResponse?.details == "otp_required") {
        this.isOTPRequired?.next(loginResponse);
      }
      else {
        console.log(loginResponse);
        this.tokenInitialization(loginResponse);

      }
    }, (error: any) => {
    });
  }

  loadSuperUserPermission() {
    this._loader.show();
    this._http.requestCall(Endpoints.GET_KEYCLOCK_PERMISSION_LIST, ApiMethod.GET).subscribe((resp: any) => {
      this._loader.hide();
      this.permissionList = resp?.data;
      let permissionCheck: any = [];
      this.permissionList.map((item: any) => {
        permissionCheck.push(item?.codename);
      })
      this._storage.setUserPermissions(JSON.stringify(permissionCheck));
      let role: any = {
        permission: permissionCheck
      }
      this.checkRole(role);
    })

  }

  checkRole(role: any, loginRes?: any) {
    this._common.supplyChainParticipant(loginRes?.groups?.[0]?.name == USERGROUPS.SUPPLY_CHAIN_PARTICIPANT_ADMINISTRATOR);
    if (loginRes?.groups?.[0]?.name == USERGROUPS.SUPPLY_CHAIN_PARTICIPANT_ADMINISTRATOR)
      this._router.navigate([`${routePath?.SCP_APPLICATION_PATH}`]);
    else
      this._router.navigate([`${routePath?.USER_DEFAULT_LIST_PATH}`]);
  }

  tokenInitialization(loginResponse: any) {
    this.navigate = false;
    if (loginResponse?.code === ErrorCodes.HTTP_200_SUCCESS) {
      this.user$.next(loginResponse);
      this._storage.setToken(loginResponse?.data?.access);
      this._storage.setRefreshToken(loginResponse?.data?.refresh);

      this._http.requestCall(Endpoints.TOKEN_VERIFY_AUTH, ApiMethod.POST).subscribe((resp: any) => {
        this._storage.setUserDetails(JSON.stringify(resp?.data));

        if (resp?.data?.is_superuser) {
          this.loadSuperUserPermission();
        } else {
          let userPermission = resp?.data?.groups[0]?.permissions || [];
          let permissionCheck: any = [];
          userPermission.map((item: any) => {
            permissionCheck.push(item?.codename);
          })

          this._storage.setUserPermissions(JSON.stringify(permissionCheck));
          let role: any = {
            permission: permissionCheck
          }
          
          if (resp?.data?.is_temporary_password) {
            this._router.navigate([`${routePath?.CHANGE_PASSWORD_REDIRECT_PATH}`]);
          } else
            this.checkRole(role, resp?.data);
        }
      })


      if (this._storage.checkTokenAvailability()) { this._snackBar.loadSnackBar(SuccessMessage.LOGIN_SUBMIT_MSG, colorCodes.SUCCESS); }
    } else {
      this._snackBar.loadSnackBar(FailedMessage.LOGIN_ERROR_MSG, colorCodes.ERROR);
    }
  }

  isLoggedIn() {
    return !!this._storage.getToken();
  }

  logout() {
    this._storage.deleteToken();
    this._router.navigate([`${routePath?.AUTH_LOGIN_REDIRECT_PATH}`]);
  }

  redirect() {
    if (this.redirectUrl) {
      this._router.navigate([this.redirectUrl]);
    }
  }

  getCurrentUser() {
    let currentUser: any = localStorage.getItem('user');
    return JSON.parse(currentUser);
  }

  permissionBasedRedirect(menu?: any) {
    try {
      if (this.navigate)
        return;
      if (!menu.children.length && this._common.gotPermission(menu.permission)) {
        this.navigate = true;
        this._router.navigate([menu?.router]);
      }
    } catch (ex: any) {
      throw new Error(ex);
    }
  }


  getResetPasswordToken() {
    let token: any;
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['tk']) {
        token = params['tk']
      } else {
        token = this._router.url.split('/')[3]
      }
    });
    return token
  }

  hasPermission(requiredPermission: any[]): boolean {
    const permission = this._storage.getPermssions();

    return permission.some((item: any) => requiredPermission.includes(item));
  }

  sendOTP(loginPayload: any): any {
    this._loader.show();
    const existingOptions = {
      headers: new HttpHeaders({
        'Error-Field-Affected': HIDENOTIFY,
      }),
    };
    let endpoint: any = `${Endpoints.POST_TOKEN_AUTH}?type=otp`;
    return this._http.requestCall(endpoint, ApiMethod.POST, loginPayload, existingOptions)
    .pipe(catchError((err: any): any => {
      this._loader.hide();
      this.loginErrorValidation.next(err);
    }))
  }
}
